import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 26, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced price by $100 for both the Revolver (now $600) and Dual Elites (now $400)"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 12, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "For a limited time, the Negev and R8 Revolver are available in Competitive Matchmaking."
      }), "\n", _jsx(_components.li, {
        children: "R8 Revolver: Price reduced to 700"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 24, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "The R8 Revolver and Negev have been removed from Competitive Matchmaking as they undergo substantial revision."
      }), "\n", _jsx(_components.li, {
        children: "Firing delay significantly reduced"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 15, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Other players can now hear the sound of the R8 Revolver primary fire hammer just before it fires."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 10, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Today we’re updating the R8 Revolver to bring its values to be more in line with other weapons: among other changes, its damage has been reduced and it takes slightly longer to fire."
      }), "\n", _jsx(_components.li, {
        children: "Base Damage Reduced from 115 to 85"
      }), "\n", _jsx(_components.li, {
        children: "Spread increased from 0.48 to 0.52"
      }), "\n", _jsx(_components.li, {
        children: "Primary fire firing delay increased from 0.333 seconds to 0.4 seconds per shot"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 9, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed exploits with the R8 Revolver, including being able to fire during freezetime or defusal, and the ability to hold the primary hammer back indefinitely."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
