import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 9, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Adjusted the CZ75-Auto to encourage semi-automatic fire at medium and long ranges."
      }), "\n", _jsx(_components.li, {
        children: "Increased recoil and fire inaccuracy"
      }), "\n", _jsx(_components.li, {
        children: "Slightly improved accuracy recovery rate"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 12, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced base damage of the CZ75-Auto from 33 to 31 (a single shot is no longer lethal against a helmeted opponent)."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 15, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details)."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 8, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased move inaccuracy on pistols: Dual Berettas, Five-SeveN, Glock-18, P2000, P250, Tec-9, USP-S, CZ75-Auto"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 8, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reverted CZ nerf to ammo and fire rate."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 10, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Magazine size has been reduced to 8 and reserve ammo is now 16 (3 mags total)."
      }), "\n", _jsx(_components.li, {
        children: "Damage has been reduced slightly."
      }), "\n", _jsx(_components.li, {
        children: "Firing rate has been reduced slightly"
      }), "\n", _jsx(_components.li, {
        children: "The CZ75-Auto takes nearly twice as long to draw, and has an updated draw animation."
      }), "\n", _jsx(_components.li, {
        children: "Kill reward is now 1/3 ($100)."
      }), "\n", _jsx(_components.li, {
        children: "Updated the weapon description."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 2, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "The CZ75-Auto has been moved from the P250 slot to the Tec-9/Five-SeveN slot and its price has been raised to $500."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 1, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Decreased standing and crouching Accurate Range"
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
