import React, { forwardRef, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import Card from "clutch/src/Card/Card.jsx";

import CaretDown from "@/inline-assets/blitz-caret-down.svg";
import CaretUp from "@/inline-assets/caret-up.svg";
import Container from "@/shared/ContentContainer.jsx";

const UpdatesCardStyle = styled(Card, forwardRef)`
  strong {
    color: #f3a722;
  }

  .card-contents {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
    overflow: hidden;
  }
`;

export const GeneralCard = styled(Card)`
  display: flex;
  flex-flow: column;
  row-gap: var(--sp-2);
  .row {
    display: flex;
    p:first-child {
      width: 40%;
    }

    p:nth-child(2) {
      color: var(--shade0);
      width: 60%;
      text-align: right;
      a,
      &.highlight {
        color: #f3a722;
      }
      a {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      img {
        width: var(--sp-5);
        height: var(--sp-5);
        margin-right: var(--sp-1);
      }
      span {
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: right;
      }
    }
  }
`;

const MainColumnsContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sp-4);

  > * {
    display: grid;
    grid-template-rows: max-content;
    gap: var(--sp-4);
  }

  .span-2 {
    grid-column: span 2;
    height: fit-content;
  }

  color: var(--shade1);
  /* TODO: Replace this with common font type */
  font-size: var(--sp-3_5e);
  line-height: var(--sp-6e);

  h4 {
    color: var(--shade0);
  }

  > ul li {
    color: var(--shade1);

    strong {
      color: var(--shade0);
    }
  }

  ul {
    list-style: inside;
    padding-left: var(--sp-1);
  }

  @container content-container (width <= 700px) {
    grid-template-columns: initial;
    .span-2,
    .span-1 {
      grid-column: initial;
    }
    .span-1 {
      grid-row: 1;
    }
  }
`;

export const UpdatesCard = ({ Updates }) => {
  const { t } = useTranslation();
  const [showingMore, setShowingMore] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const content = ref.current.querySelector(".card-contents");
      if (content.scrollHeight > content.clientHeight) {
        setShowFooter(true);
      }
    }
  }, []);
  const footerProps = showFooter
    ? {
        footerButtonText: showingMore
          ? t("common:showLess", "Show less")
          : t("common:showMore", "Show more"),
        footerButtonIcon: showingMore ? <CaretUp /> : <CaretDown />,
        footerButtonFn: () => {
          setShowingMore((prev) => !prev);
        },
      }
    : {};

  return (
    <UpdatesCardStyle
      ref={ref}
      style={{
        maxHeight: showingMore ? "fit-content" : "calc(var(--sp-10) * 10)",
      }}
      {...footerProps}
    >
      <h4>{t("cs2:database.updateHistory", "Update History")}</h4>
      <Updates />
    </UpdatesCardStyle>
  );
};

export const OverviewCard = styled(Card)`
  &.card-contents {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);

    a {
      color: #f3a722;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export function DatabaseContainer({ children }) {
  return (
    <Container>
      <MainColumnsContainer>{children}</MainColumnsContainer>
    </Container>
  );
}
