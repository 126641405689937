import React from "react";
import { useTranslation } from "react-i18next";

import type { Weapon } from "@/game-cs2-marketing/constants.mjs";
import { WEAPON_CATEGORIES, WEAPONS } from "@/game-cs2-marketing/constants.mjs";
import DatabaseArmorStats from "@/game-cs2-marketing/DatabaseArmorStats.jsx";
import {
  DatabaseContainer,
  OverviewCard,
  UpdatesCard,
} from "@/game-cs2-marketing/DatabaseComponents.jsx";
import DatabaseGrenadeStats from "@/game-cs2-marketing/DatabaseGrenadeStats.jsx";
import DatabaseWeaponGeneral from "@/game-cs2-marketing/DatabaseWeaponGeneral.jsx";
import DatabaseWeaponStats from "@/game-cs2-marketing/DatabaseWeaponStats.jsx";
// import DatabaseWeaponStatsTrends from "@/game-cs2-marketing/DatabaseWeaponStatsTrends.jsx";
import WEAPON_UPDATES from "@/game-cs2-marketing/weapon-updates/updates.mjs";

export type WeaponStatType = {
  label: Translation;
  value: (weapon: Weapon) => Translation | string | null;
};

function getStatsComponent(weapon: Weapon) {
  if (weapon.category === "grenades") {
    return DatabaseGrenadeStats;
  } else if (weapon.key === "kevlarAndHelmet") {
    return DatabaseArmorStats;
  } else if (weapon.category !== "gear") {
    return DatabaseWeaponStats;
  }

  return null;
}

function DatabaseWeaponOverview({ weapon: weaponParam }) {
  const { t } = useTranslation();
  const [/*weaponId, */ _, weapon] = Object.entries(WEAPONS).find(
    ([, weapon]) => weapon.key === weaponParam,
  );
  const weaponCategory = WEAPON_CATEGORIES.find(
    (wc) => wc.value === weapon?.category,
  );

  const StatsComponent = getStatsComponent(weapon);

  if (!weapon) return null;
  return (
    <DatabaseContainer>
      <div className="span-2">
        {weapon.description ? (
          <OverviewCard>
            <h4>{t("cs2:database.weapons.overview", "Overview")}</h4>
            <p
              className="type-callout--semi"
              dangerouslySetInnerHTML={{ __html: weapon.description }}
            />
          </OverviewCard>
        ) : null}
        {/* {weaponCategory.value !== "gear" &&
        weaponCategory.value !== "grenades" ? (
          <DatabaseWeaponStatsTrends weaponId={weaponId} />
        ) : null} */}
        {StatsComponent ? <StatsComponent category={weaponCategory} /> : null}
        {WEAPON_UPDATES[weapon.key] ? (
          <UpdatesCard Updates={WEAPON_UPDATES[weapon.key]} />
        ) : null}
      </div>
      <div className="span-1">
        <DatabaseWeaponGeneral weapon={weapon} />
      </div>
    </DatabaseContainer>
  );
}

export default DatabaseWeaponOverview;
