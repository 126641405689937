import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 14, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "The P250 has been adjusted to reward longer-distance engagements, particularly against unarmored opponents."
      }), "\n", _jsx(_components.li, {
        children: "Increased base damage"
      }), "\n", _jsx(_components.li, {
        children: "Reduced armor penetration"
      }), "\n", _jsx(_components.li, {
        children: "Improved damage at longer distances"
      }), "\n", _jsx(_components.li, {
        children: "Reduced accuracy while moving"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 3, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased fidelity of firing sounds for P250, Five-Seven, Tec-9, CZ75-Auto, and Dual Berettas."
      }), "\n", _jsx(_components.li, {
        children: "Added unique reload and distant sounds for P250, Five-Seven, Tec-9, and Dual Berettas."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 15, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details)."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 8, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased move inaccuracy on pistols: Dual Berettas, Five-SeveN, Glock-18, P2000, P250, Tec-9, USP-S, CZ75-Auto"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "January 8, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced P250 ammo from 5 magazines to 3"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 13, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced effective range."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
