import React from "react";
import { useTranslation } from "react-i18next";

import { WeaponImage } from "@/game-cs2-marketing/CommonComponents.jsx";
import type {
  Weapon,
  WeaponCategory,
} from "@/game-cs2-marketing/constants.mjs";
import { WEAPONS } from "@/game-cs2-marketing/constants.mjs";
import CounterTerroristIcon from "@/inline-assets/cs2-counter-terrorist.webp";
import CounterTerroristAndTerroristIcon from "@/inline-assets/cs2-counter-terrorist-terrorist.webp";
import TerroristIcon from "@/inline-assets/cs2-terrorist.webp";
import DataTable from "@/shared/DataTable";

export const Sides = ({ weapon }: { weapon: Weapon }) => {
  const icon =
    weapon.usedBy.length === 2
      ? CounterTerroristAndTerroristIcon
      : weapon.usedBy?.[0] === "terrorists"
        ? TerroristIcon
        : weapon.usedBy[0] === "counter-terrorists"
          ? CounterTerroristIcon
          : null;
  if (!icon) return null;
  return (
    <img src={icon} alt="side icon" width="var(--sp-5)" height="var(--sp-5)" />
  );
};

function DatabaseWeaponStats({ category }: { category: WeaponCategory }) {
  const { t } = useTranslation();
  const cols = [
    {
      display: t(category.i18nKey, category.text),
      align: "left",
      primary: true,
    },
    { display: t("cs2:side", "Side"), align: "center" },
    {
      display: t("cs2:weapons.damagePerSec", "Damage / Sec"),
      align: "right",
      isStat: true,
    },
    { display: t("cs2:price", "Price"), align: "right", isStat: true },
    { display: t("cs2:killAward", "Kill Award"), align: "right", isStat: true },
  ];

  const weapons = Object.values(WEAPONS).filter(
    (w) => w.category === category.value,
  );
  const rows = weapons.map((w) => {
    return [
      {
        display: <WeaponImage weapon={w} />,
        value: w.name,
      },
      {
        display: <Sides weapon={w} />,
        value: w.usedBy.length,
      },
      {
        display: w?.damagePerSecond || 0,
        value: w?.damagePerSecond || 0,
      },
      {
        display: t("cs2:stats:weapons.price", "$ {{price}}", {
          price: w.price.toLocaleString(),
        }),
        value: w.price,
      },
      {
        display: t("cs2:stats:weapons.killAward", "$ {{killAward}}", {
          killAward: (w.killAward?.competitive || 0).toLocaleString(),
        }),
        value: w.killAward?.competitive || 0,
      },
    ];
  });

  return (
    <DataTable
      cols={cols}
      rows={rows}
      sortDir="DESC"
      sortCol={2}
      sortColTiebreak={3}
      indexCol
    />
  );
}

export default DatabaseWeaponStats;
