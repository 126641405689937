import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import i18n from "i18next";

import { WEAPONS } from "@/game-cs2-marketing/constants.mjs";
import DatabaseWeaponOverview from "@/game-cs2-marketing/DatabaseWeaponOverview.jsx";
import { getWeaponIcon } from "@/game-cs2-marketing/utils.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader";
import { useRoute } from "@/util/router-hooks.mjs";

const TABS = [
  {
    key: "overview",
    text: "Overview",
    component: DatabaseWeaponOverview,
  },
];

const WeaponIcon = styled("div")`
  display: flex;
  background: var(--shade7);
  border-radius: var(--br-lg);
  width: calc(2 * var(--sp-10));
  height: var(--sp-10);
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  &::before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: var(--sp-0_5) solid var(--shade4);
    border-radius: var(--br-xl);
  }
`;

function Weapon() {
  const {
    parameters: [weaponParam, tab],
  } = useRoute();

  const { t } = useTranslation();

  const weapon = Object.values(WEAPONS).find(
    (weapon) => weapon.key === weaponParam,
  );

  const links = TABS.map((tab) => {
    return {
      url: `/cs2/database/weapons/${weapon?.key}/${tab.key}`,
      text: tab.text,
    };
  });
  const selectedTab = TABS.find((t) => t.key === tab);
  const Component = selectedTab?.component;

  if (!weapon) return null;

  return (
    <PageContainer>
      <PageHeader
        title={t(`cs2.weapons.${weapon.i18nKey}`, weapon.name)}
        ImageComponent={
          <WeaponIcon>
            <img src={getWeaponIcon(weapon.icon)} />
          </WeaponIcon>
        }
        links={links}
      />
      <Component weapon={weaponParam} />
    </PageContainer>
  );
}

export function meta([tab]) {
  const { t } = i18n;
  const weapon = Object.values(WEAPONS).find((o) => o.key === tab)?.name;
  const weaponName = t(`csgo:weapons.${tab}`, weapon);

  return {
    title: [
      "csgo:meta.weapon.title",
      "{{weaponName}} Stats - Avg. Headshot %, Kills/Match, and More",
      { weaponName },
    ],
    description: [
      "csgo:meta.weapon.description",
      "Find out how the {{weaponName}} performs on average and compare it to other CS:GO weapons.",
      { weaponName },
    ],
  };
}

export default Weapon;
