import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import Mannequin from "@/inline-assets/mannequin.svg";

const Container = styled("table")`
  svg {
    height: calc(2.125 * var(--sp-10));
  }

  color: var(--shade1);

  th {
    text-transform: uppercase;
    font-weight: 700;
  }

  .body-chest {
    color: var(--shade2-50);
  }

  .body-stomach,
  .body-legs {
    color: var(--shade2-25);
  }

  .fatal-hit {
    position: relative;
    text-align: center;
  }

  .fatal-hit::before {
    content: "";
    height: var(--sp-2);
    width: var(--sp-2);
    background: var(--red);
    border-radius: var(--sp-0_5);
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: calc(-1 * var(--sp-3));
    margin-top: auto;
    margin-bottom: auto;
  }

  &#gear-damage {
    td div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 50%;
        transform: translateX(-50%);
      }
      span {
        margin-bottom: var(--sp-3);
        margin-right: var(--sp-3);
      }
    }
  }
`;

function DatabaseWeaponDamage({ weapon }) {
  const { t } = useTranslation();
  if (weapon.category === "gear") {
    return (
      <Container id="gear-damage">
        <thead>
          <tr>
            <th className="type-callout--semi">
              {t("cs2:mannequin.default", "Default")}
            </th>
            <th className="type-callout--semi">
              {t("cs2:mannequin.armored", "Armored")}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <Mannequin />
                <span>{weapon.damageUnarmored.head}</span>
              </div>
            </td>
            <td>
              <div>
                <Mannequin />
                <span>{weapon.damageArmored.head}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </Container>
    );
  }
  return (
    <Container>
      <thead>
        <tr>
          <th></th>
          <th className="type-callout--semi" colSpan={2}>
            {t("cs2:mannequin.default", "Default")}
          </th>
          <th className="type-callout--semi" colSpan={2}>
            {t("cs2:mannequin.armored", "Armored")}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="type-callout--semi">
            {t("cs2:mannequin.head", "Head")}
          </td>
          <td rowSpan={4}>
            <Mannequin />
          </td>
          <td className="type-callout--semi color-red">
            {weapon.damageUnarmored.head}
          </td>
          <td rowSpan={4}>
            <Mannequin />
          </td>
          <td className="type-callout--semi color-red">
            {weapon.damageArmored.head}
          </td>
        </tr>
        <tr>
          <td className="type-callout--semi">
            {t("cs2:mannequin.chestArm", "Chest / Arm")}
          </td>
          <td className="type-callout--semi shade0">
            {weapon.damageUnarmored.chest}
          </td>
          <td className="type-callout--semi shade0">
            {weapon.damageArmored.chest}
          </td>
        </tr>
        <tr>
          <td className="type-callout--semi">
            {t("cs2:mannequin.stomach", "Stomach")}
          </td>
          <td className="type-callout--semi shade0">
            {weapon.damageUnarmored.stomach}
          </td>
          <td className="type-callout--semi shade0">
            {weapon.damageArmored.stomach}
          </td>
        </tr>
        <tr>
          <td>{t("cs2:mannequin.legs", "Legs")}</td>
          <td className="type-callout--semi shade0">
            {weapon.damageUnarmored.legs}
          </td>
          <td className="type-callout--semi shade0">
            {weapon.damageArmored.legs}
          </td>
        </tr>
        <tr>
          <td className="type-callout--semi fatal-hit" colSpan={5}>
            {t("cs2:database.weapons.fatalHit", "Fatal Hit")}
          </td>
        </tr>
      </tbody>
    </Container>
  );
}

export default DatabaseWeaponDamage;
