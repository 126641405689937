import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { Weapon } from "@/game-cs2-marketing/constants.mjs";
import { SIDES, WEAPONS } from "@/game-cs2-marketing/constants.mjs";
import { GeneralCard } from "@/game-cs2-marketing/DatabaseComponents.jsx";
import DatabaseWeaponDamage from "@/game-cs2-marketing/DatabaseWeaponDamage";
import type { WeaponStatType } from "@/game-cs2-marketing/DatabaseWeaponOverview";
import DatabaseWeaponPattern from "@/game-cs2-marketing/DatabaseWeaponPattern";
import { getWeaponIcon } from "@/game-cs2-marketing/utils.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import CounterTerroristIcon from "@/inline-assets/cs2-counter-terrorist.webp";
import TerroristIcon from "@/inline-assets/cs2-terrorist.webp";
import WeaponBackground from "@/inline-assets/weapon-background.webp";
import { formatToPercent } from "@/util/i18n-helper.mjs";

const WEAPON_STATS_ROWS: WeaponStatType[] = [
  {
    label: ["cs2:database.weapons.rateOfFire", "Rate Of Fire"],
    value: (weapon: Weapon) =>
      weapon.rateOfFire?.normal
        ? [
            "cs2:weapons.stats.rateOfFire",
            "{{ rounds }} per minute",
            { rounds: weapon.rateOfFire?.normal },
          ]
        : null,
  },
  {
    label: ["cs2:database.weapons.damage", "Damage"],
    value: (weapon: Weapon) =>
      weapon.damagePerSecond
        ? [
            "cs2:weapons.stats.damagePerSecond",
            "{{ dps }} per second",
            { dps: weapon.damagePerSecond },
          ]
        : null,
  },
  {
    label: ["cs2:database.weapons.reloadTime", "Reload Time"],
    value: (weapon: Weapon) =>
      weapon.reloadTime
        ? [
            "cs2:weapons.stats.reloadTime",
            "{{ time }} seconds",
            { time: weapon.reloadTime },
          ]
        : null,
  },
  {
    label: ["cs2:database.weapons.magazine", "Magazine"],
    value: (weapon: Weapon) =>
      weapon.magazine
        ? [
            "cs2:weapons.stats.magazine",
            "{{ loadedRounds }} / {{ remainingRounds }}",
            {
              loadedRounds: weapon.magazine.loadedRounds,
              remainingRounds: weapon.magazine.remainingRounds,
            },
          ]
        : null,
  },
  {
    label: ["cs2:database.weapons.penetration", "Penetration"],
    value: (weapon: Weapon) =>
      weapon.armorPenetration
        ? formatToPercent(undefined, weapon.armorPenetration, {
            min: 1,
            max: 1,
          })
        : null,
  },
  {
    label: ["cs2:database.weapons.runSpeed", "Run Speed"],
    value: (weapon: Weapon) => [
      "cs2:weapons.stats.runSpeed",
      "{{ runSpeed }} u/s",
      { runSpeed: weapon.movement.running },
    ],
  },
];

const WeaponImage = styled("div")`
  width: 100%;
  height: calc(3.5 * var(--sp-10));
  position: relative;
  margin-bottom: var(--sp-2);
  overflow: hidden;
  border-radius: var(--br-lg);

  img {
    width: 100%;
    height: auto;
  }

  .weapon {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: auto;
  }
`;

const Spacer = styled("div")`
  width: 100%;
  height: var(--sp-0_5);
  background: var(--shade3-15);
  margin: var(--sp-3) 0;
`;

const WeaponCounterpart = styled("p")`
  display: flex;
  justify-content: end;
  gap: var(--sp-1);
`;

function General({ weapon }) {
  const { t } = useTranslation();

  return (
    <GeneralCard>
      <WeaponImage>
        <img src={WeaponBackground} alt="weapon background" />
        <img
          className="weapon"
          src={getWeaponIcon(weapon.icon, "250x80")}
          alt="weapon icon"
        />
      </WeaponImage>
      <div className="row">
        <p className="type-callout--semi">{t("cs2:weapons.price", "Price")}</p>
        <p className="type-callout--semi">
          {weapon.price === 0
            ? t("common:free", "Free")
            : t("cs2:stats:weapons.price", "$ {{price}}", {
                price: weapon.price.toLocaleString(),
              })}
        </p>
      </div>
      {weapon.killAward && (
        <div className="row">
          <p className="type-callout--semi">
            {t("cs2:weapons.killAward", "Kill Award")}
          </p>
          <p className="type-callout--semi">
            {t("cs2:stats:weapons.killAward", "$ {{killAward}}", {
              killAward: weapon.killAward.competitive.toLocaleString(),
            })}
          </p>
        </div>
      )}
      <div className="row">
        <p className="type-callout--semi">
          {t("cs2:weapons.usedBy", "Used By")}
        </p>
        <p className="type-callout--semi highlight">
          {weapon.usedBy.map((usedBy, index) => {
            const side = SIDES.find((s) => s.value === usedBy);
            return (
              <span key={index}>
                <img
                  src={
                    usedBy === "terrorists"
                      ? TerroristIcon
                      : CounterTerroristIcon
                  }
                  alt="cs2-side-icon"
                  width="var(--sp-5)"
                  height="var(--sp-5)"
                />{" "}
                {t(side.i18nKey, side.text)}
              </span>
            );
          })}
        </p>
      </div>
      {weapon.damageArmored && weapon.damageUnarmored ? (
        <>
          <Spacer />
          <h4>
            {weapon.category === "gear"
              ? t("cs2:database.maximumDamage", "Maximum Damage")
              : t("cs2:database.damage", "Damage")}
          </h4>
          <DatabaseWeaponDamage weapon={weapon} />
        </>
      ) : null}
      {weapon.recoilPattern ? (
        <>
          <Spacer />
          <DatabaseWeaponPattern
            recoilPattern={weapon.recoilPattern}
            sprayPattern={weapon.sprayPattern}
          />
        </>
      ) : null}
      {weapon.category !== "gear" && (
        <>
          <Spacer />
          <h4>{t("cs2:database.statistics", "Statistics")}</h4>
          {WEAPON_STATS_ROWS.map((statsRow, index) => {
            const value = statsRow.value(weapon);
            if (!value) return null;
            return (
              <div className="row" key={index}>
                <p className="type-callout--semi">{t(...statsRow.label)}</p>
                <p className="type-callout--semi">
                  {typeof value === "string" ? value : t(...value)}
                </p>
              </div>
            );
          })}
        </>
      )}
      {weapon.category === "gear" && weapon.movement && (
        <>
          <Spacer />
          <h4>{t("cs2:database.movementSpeed", "Movement Speed")}</h4>
          <div className="row">
            <p className="type-callout--semi">
              {t("cs2:database.weapons.running", "Running")}
            </p>
            <p className="type-callout--semi">
              {t("cs2:weapons.stats.runSpeed", "{{ runSpeed }} u/s", {
                runSpeed: weapon.movement.running,
              })}
            </p>
          </div>
          <div className="row">
            <p className="type-callout--semi">
              {t("cs2:database.weapons.walking", "Walking")}
            </p>
            <p className="type-callout--semi">
              {t("cs2:weapons.stats.walkSpeed", "{{ walkSpeed }} u/s", {
                walkSpeed: weapon.movement.walking,
              })}
            </p>
          </div>
          <div className="row">
            <p className="type-callout--semi">
              {t("cs2:database.weapons.crouching", "Crouching")}
            </p>
            <p className="type-callout--semi">
              {t("cs2:weapons.stats.walkSpeed", "{{ crouchSpeed }} u/s", {
                crouchSpeed: weapon.movement.crouching,
              })}
            </p>
          </div>
        </>
      )}
      {weapon.counterpart?.length > 0 ? (
        <>
          <Spacer />
          <h4>{t("cs2:database.other", "Other")}</h4>
          <div className="row">
            <p className="type-callout--semi">
              {t("cs2:database.couterpart", "Counterpart")}
            </p>
            <WeaponCounterpart className="type-callout--semi">
              {weapon.counterpart.map((id, index) =>
                WEAPONS[id] ? (
                  <span key={id}>
                    <a
                      href={`/${GAME_SHORT_NAMES[GAME_SYMBOL_CS2]}/database/weapons/${WEAPONS[id].key}/overview`}
                      key={id}
                    >
                      {WEAPONS[id].name}
                    </a>
                    {index !== weapon.counterpart.length - 1 ? (
                      <span className="shade0">/</span>
                    ) : null}
                  </span>
                ) : null,
              )}
            </WeaponCounterpart>
          </div>
        </>
      ) : null}
    </GeneralCard>
  );
}

export default General;
