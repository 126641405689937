import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 13, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Price reduction for some shotguns: Nova ($1050), Sawed-Off ($1100), MAG-7 ($1300)."
      }), "\n", _jsx(_components.li, {
        children: "Range increased to 1400."
      }), "\n", _jsx(_components.li, {
        children: "Single shell shotguns in the middle of their reload sequence will no longer automatically continue to reload after a player holsters and deploys them."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 8, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Enabled shotgun spread patterns on official matchmaking servers as described in ", _jsx(_components.a, {
          href: "https://blog.counter-strike.net/index.php/holiday-spread/",
          children: "https://blog.counter-strike.net/index.php/holiday-spread/"
        })]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 1, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Penetration power increased"
      }), "\n", _jsx(_components.li, {
        children: "Pellets reduced to 8"
      }), "\n", _jsx(_components.li, {
        children: "Individual pellet damage increased to 32"
      }), "\n", _jsx(_components.li, {
        children: "Reduced maximum range slightly"
      }), "\n", _jsx(_components.li, {
        children: "Spread increased slightly"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 1, 2012"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced all shotguns' price by $300."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
