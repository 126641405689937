import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "May 22, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Smoke, HE Grenade explosions, and fire are now always displayed consistently between all players."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 12, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed the ‘radar spotting enemies through smoke’ mechanic to behave the same regardless of the game server tickrate, and to not reveal enemies on the opposite side of the smoke until the smoke effect dissipates."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 26, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fixed rare cases when smoke would not extinguish fire when it bounces between the flames."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 31, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Smoke grenades are now more reliably kept track of on the client to prevent mismatches between smoke particle visuals and screen overlay visuals."
      }), "\n", _jsx(_components.li, {
        children: "Fixed players being able to double stack inside a smoke to peek out of the top (and be really hard to see from outside the smoke)."
      }), "\n", _jsx(_components.li, {
        children: "Updated expiration of the smoke screen overlay to match more closely to the visuals of when the third person particles expire and vice versa."
      }), "\n", _jsx(_components.li, {
        children: "Updated the vertical position slightly of the smoke particles to reduce seeing though edges of the smoke (particularly at the bottom)."
      }), "\n", _jsx(_components.li, {
        children: "Fixed spectator sometimes not getting the smoke overlay for the player they are spectating when that alive player is inside smoke."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 1, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Flagged smoke grenade particles to face player camera position instead of camera direction."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "November 16, 2012"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Smoke grenade visibility on the edges of smokes has been adjusted to obscure vision less."
      }), "\n", _jsx(_components.li, {
        children: "Improved some cases of particle sorting between smoke and molotovs and other particle systems."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
