import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { WeaponImage } from "@/game-cs2-marketing/CommonComponents.jsx";
import type {
  Weapon,
  WeaponCategory,
} from "@/game-cs2-marketing/constants.mjs";
import { WEAPONS } from "@/game-cs2-marketing/constants.mjs";
import CounterTerroristIcon from "@/inline-assets/cs2-counter-terrorist.webp";
import TerroristIcon from "@/inline-assets/cs2-terrorist.webp";
import DataTable from "@/shared/DataTable";

const SidesContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: var(--sp-2);
`;

export const Sides = ({ weapon }: { weapon: Weapon }) => {
  return (
    <SidesContainer>
      {weapon.usedBy.map((side) => (
        <img
          key={side}
          src={side === "terrorists" ? TerroristIcon : CounterTerroristIcon}
          alt="side icon"
          width="var(--sp-5)"
          height="var(--sp-5)"
        />
      ))}
    </SidesContainer>
  );
};

function DatabaseGrenadeStats({ category }: { category: WeaponCategory }) {
  const { t } = useTranslation();
  const cols = [
    {
      display: t(category.i18nKey, category.text),
      align: "left",
      primary: true,
    },
    {
      display: t("cs2:side", "Side"),
      align: "center",
    },
    {
      display: t("cs2:price", "Price"),
      align: "center",
      isStat: true,
    },
    {
      display: t("cs2:damageUnarmoredShort", "DMG"),
      align: "center",
      isStat: true,
      tooltip: t("cs2:damageUnarmored", "Damage against Unarmored"),
    },
    {
      display: t("cs2:damageArmoredShort", "ARM DMG"),
      align: "center",
      isStat: true,
      tooltip: t("cs2:damageArmored", "Damage against Armored"),
    },
  ];

  const weapons = Object.values(WEAPONS).filter(
    (w) => w.category === category.value,
  );
  const rows = weapons.map((w) => {
    return [
      {
        display: <WeaponImage weapon={w} compact={true} />,
        value: w.name,
      },
      {
        display: <Sides weapon={w} />,
        value: w.usedBy.length,
      },
      {
        display: t("cs2:stats:weapons.price", "$ {{price}}", {
          price: w.price.toLocaleString(),
        }),
        value: w.price,
      },
      {
        display: ((w.damageUnarmored?.head as number) || 0).toLocaleString(),
        value: (w.damageUnarmored?.head as number) || 0,
      },
      {
        display: ((w.damageArmored?.head as number) || 0).toLocaleString(),
        value: (w.damageArmored?.head as number) || 0,
      },
    ];
  });

  return (
    <DataTable
      cols={cols}
      rows={rows}
      sortDir="DESC"
      sortCol={2}
      sortColTiebreak={3}
      indexCol
    />
  );
}

export default DatabaseGrenadeStats;
