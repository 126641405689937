import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 10, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced firing inaccuracy"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "August 8, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Shipping initial changes to pistols with the goal of emphasizing skilful use of the weapons, beginning with the Tec-9."
      }), "\n", _jsx(_components.li, {
        children: "Tec-9: The Tec-9 has been adjusted to emphasize aiming while retaining the weapon’s high mobility. The current changes include:"
      }), "\n", _jsx(_components.li, {
        children: "slightly improved accuracy when taking a single shot"
      }), "\n", _jsx(_components.li, {
        children: "significantly reduced accuracy when firing rapidly"
      }), "\n", _jsx(_components.li, {
        children: "magazine and reserve ammo reduced to 18 and 90, respectively"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 15, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details)."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 8, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased move inaccuracy on pistols: Dual Berettas, Five-SeveN, Glock-18, P2000, P250, Tec-9, USP-S, CZ75-Auto"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 31, 2015"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased Tec-9’s damage fall-off to reward players that close engagement distance."
      }), "\n", _jsx(_components.li, {
        children: "Lowered Tec-9’s magazine size to 24 to reward more discriminate firing."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "December 10, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Updated the Tec-9 firing sound"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 20, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced Five-SeveN and Tec-9 prices"
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
