import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 22, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Improved accuracy while firing in bursts"
      }), "\n", _jsx(_components.li, {
        children: "Slightly reduced time until significant accuracy recovery"
      }), "\n", _jsx(_components.li, {
        children: "Price lowered to $1700"
      }), "\n", _jsx(_components.li, {
        children: "Reduced armor penetration"
      }), "\n", _jsx(_components.li, {
        children: "Movement speed is significantly reduced while firing"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 12, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "For a limited time, the Negev and R8 Revolver are available in Competitive Matchmaking."
      }), "\n", _jsx(_components.li, {
        children: "Price reduced to 2000 as a starting point, to promote experimentation with the weapon."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 24, 2017"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "R8 Revolver and Negev have been removed from Competitive Matchmaking as they undergo substantial revision."
      }), "\n", _jsx(_components.li, {
        children: "New attributes to promote suppressive fire."
      }), "\n", _jsx(_components.li, {
        children: "Reduced price."
      }), "\n", _jsx(_components.li, {
        children: "New sounds that dynamically indicate weapon accuracy."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
