import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import {
  getRecoilPattern,
  getSprayPattern,
} from "@/game-cs2-marketing/utils.mjs";
import InfoIcon from "@/inline-assets/blitz-info-border.svg";

const Pattern = styled("table")`
  table-layout: fixed;
  width: 100%;

  th {
    padding-bottom: var(--sp-2);
    color: var(--shade0);
    div {
      display: flex;
      gap: var(--sp-2);
      align-items: center;
    }

    svg {
      width: var(--sp-4_5);
      height: var(--sp-4_5);
      color: var(--shade1);
    }
  }

  td {
    height: calc(5.3 * var(--sp-10));

    div {
      padding: var(--sp-5);
      border: var(--sp-px) solid var(--shade3-15);
      border-radius: var(--br);
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        max-width: 100%;
        margin: auto;
      }
    }
  }
  th,
  td {
    &:first-child div {
      margin-right: var(-sp-1);
    }
    &:last-child div {
      margin-left: var(--sp-1);
    }
  }
`;

const Container = styled("div")`
  width: 100%;
`;

function DatabaseWeaponPattern({ recoilPattern, sprayPattern }) {
  const { t } = useTranslation();

  return (
    <Container>
      <Pattern>
        <thead>
          <tr>
            <th>
              <div>
                {t("cs2:recoilPattern.title", "Recoil Pattern")}
                <span
                  data-tip={t(
                    "cs2:recoilPattern.desc",
                    "Recoil control is the technique of managing and countering the backward and upward movement of a weapon when fired to maintain accuracy and target alignment.",
                  )}
                >
                  <InfoIcon />
                </span>
              </div>
            </th>
            {sprayPattern ? (
              <th>
                <div>
                  {t("cs2:sprayPattern.title", "Spray Pattern")}{" "}
                  <span
                    data-tip={t(
                      "cs2:sprayPattern.desc",
                      "A spray pattern is the predictable trajectory of bullets when continuously firing a weapon, which players learn to control for accuracy.",
                    )}
                  >
                    <InfoIcon />
                  </span>
                </div>
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <img
                  src={getRecoilPattern(recoilPattern)}
                  alt="recoil pattern"
                />
              </div>
            </td>
            {sprayPattern ? (
              <td>
                <div>
                  <img
                    src={getSprayPattern(sprayPattern)}
                    alt="spray pattern"
                  />
                </div>
              </td>
            ) : null}
          </tr>
        </tbody>
      </Pattern>
    </Container>
  );
}

export default DatabaseWeaponPattern;
