import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "April 10, 2020"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "SG553 – Reduced rate of fire and accuracy to bring the weapon’s value to be more in line with other rifles."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "November 18, 2019"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased price of SG553 to $3000 to bring its price more in line with its value."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "October 9, 2018"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "SG553 price reduced to $2750"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "September 28, 2016"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased fidelity and reduced distortion in fire sounds for Famas, Galil, Aug, SG553, M4A4, M4A1-S, unsilenced M4A1-S, and AK47."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "July 27, 2016"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added tracers to alt fire on Aug, Sg, Glock, and Famas."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "February 20, 2014"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["[AUG and SG553]", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "Scope dot no longer fades too quickly during online play."
          }), "\n", _jsx(_components.li, {
            children: "Scope dot is slightly more visible against bright backgrounds."
          }), "\n", _jsx(_components.li, {
            children: "Scope dot is now tinted using the player’s crosshair color settings."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "February 12, 2014"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Reduced AUG and SG553 scoped run speeds"
      }), "\n", _jsx(_components.li, {
        children: "Improved scope visuals for Aug and Sg553."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "February 5, 2014"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Improved SG553 and AUG rates of fire."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "June 26, 2013"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Further adjustments to the AUG/SG553."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "June 19, 2013"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Tuned the AUG and SG553 to function more effectively as a rifle/sniper hybrid."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
