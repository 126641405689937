import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Select } from "clutch/src/Select/Select";
import { TextInput } from "clutch/src/TextInput/TextInput";

import { WeaponImage } from "@/game-cs2-marketing/CommonComponents.jsx";
import type { WeaponCategory } from "@/game-cs2-marketing/constants.mjs";
import { WEAPON_CATEGORIES, WEAPONS } from "@/game-cs2-marketing/constants.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import DataTable from "@/shared/DataTable";
import FilterBar from "@/shared/FilterBar.jsx";
import { formatToPercent, getLocale } from "@/util/i18n-helper.mjs";

const Container = styled("div")`
  width: 100%;
  .filter {
    margin-bottom: var(--sp-4);
  }
`;

const FilterWeaponCategory = ({ onChange, selected }) => {
  const options = useMemo(() => {
    const disallowedCategories = ["all", "gear", "grenades", "utilities"];
    return WEAPON_CATEGORIES.filter(
      ({ value }) => !disallowedCategories.includes(value),
    ).map(({ value, i18nKey, text }) => ({
      value,
      text: [i18nKey, text] satisfies Translation,
    }));
  }, []);

  return <Select onChange={onChange} selected={selected} options={options} />;
};

function DatabaseArmorStats({ category }: { category: WeaponCategory }) {
  const { t } = useTranslation();
  const [searchText, setSearch] = useState("");
  const [filterCategory, setFilterCategory] = useState("pistols");
  const cols = [
    {
      display: t(category.i18nKey, category.text),
      align: "left",
      primary: true,
    },
    { display: t("cs2:stats.dmg", "DMG"), align: "center", isStat: true },
    {
      display: t("cs2:stats.armorPenetration", "Armor Pen."),
      align: "center",
      isStat: true,
    },
    { display: t("cs2:stats.hpDmg", "HP DMG"), align: "center", isStat: true },
    {
      display: t("cs2:stats.armorAbsorption", "Armor Absorb."),
      align: "center",
      isStat: true,
    },
  ];

  const weapons = Object.values(WEAPONS).filter(
    (w) =>
      w.category !== "gear" &&
      w.category !== "grenades" &&
      w.category === filterCategory &&
      w.name.includes(searchText),
  );
  const rows = weapons.map((w) => {
    return [
      {
        display: <WeaponImage weapon={w} />,
        value: w.name,
      },
      {
        display: w?.baseDamage || 0,
        value: w?.baseDamage || 0,
      },
      {
        display: formatToPercent(getLocale(), w?.armorPenetration / 100, {
          min: 0,
          max: 2,
        }),
        value: w?.armorPenetration,
      },
      {
        display: w?.baseHPDamage,
        value: w?.baseHPDamage || 0,
      },
      {
        display: w?.armorAbsorption,
        value: w?.armorAbsorption || 0,
      },
    ];
  });

  return (
    <Container>
      <FilterBar hiddenItems={undefined} className="filter">
        <TextInput
          defaultValue={searchText}
          placeholder={t("csgo:search.weapons", "Search Weapons")}
          onValueChange={setSearch}
          Icon={SearchIcon}
        />
        <FilterWeaponCategory
          onChange={setFilterCategory}
          selected={filterCategory}
        />
      </FilterBar>
      <DataTable
        cols={cols}
        rows={rows}
        sortDir="DESC"
        sortCol={2}
        sortColTiebreak={3}
        indexCol
      />
    </Container>
  );
}

export default DatabaseArmorStats;
