import type { ComponentType } from "react";

import WeaponAK47 from "@/game-cs2-marketing/weapon-updates/weapon_ak47.mdx";
import WeaponAUG from "@/game-cs2-marketing/weapon-updates/weapon_aug.mdx";
import WeaponAWP from "@/game-cs2-marketing/weapon-updates/weapon_awp.mdx";
import WeaponBizon from "@/game-cs2-marketing/weapon-updates/weapon_bizon.mdx";
import WeaponC4 from "@/game-cs2-marketing/weapon-updates/weapon_c4.mdx";
import WeaponCZ75A from "@/game-cs2-marketing/weapon-updates/weapon_cz75a.mdx";
import WeaponDeagle from "@/game-cs2-marketing/weapon-updates/weapon_deagle.mdx";
import WeaponDecoy from "@/game-cs2-marketing/weapon-updates/weapon_decoy.mdx";
import WeaponDefuseKit from "@/game-cs2-marketing/weapon-updates/weapon_defuse_kit.mdx";
import WeaponElite from "@/game-cs2-marketing/weapon-updates/weapon_elite.mdx";
import WeaponFamas from "@/game-cs2-marketing/weapon-updates/weapon_famas.mdx";
import WeaponFiveSeven from "@/game-cs2-marketing/weapon-updates/weapon_fiveseven.mdx";
import WeaponFlashBang from "@/game-cs2-marketing/weapon-updates/weapon_flashbang.mdx";
import WeaponG3SG1 from "@/game-cs2-marketing/weapon-updates/weapon_g3sg1.mdx";
import WeaponGalilar from "@/game-cs2-marketing/weapon-updates/weapon_galilar.mdx";
import WeaponGlock from "@/game-cs2-marketing/weapon-updates/weapon_glock.mdx";
import WeaponHEGrenade from "@/game-cs2-marketing/weapon-updates/weapon_hegrenade.mdx";
import WeaponHKP2000 from "@/game-cs2-marketing/weapon-updates/weapon_hkp2000.mdx";
import WeaponIncendiary from "@/game-cs2-marketing/weapon-updates/weapon_incendiary.mdx";
import WeaponKevlarAndHelmet from "@/game-cs2-marketing/weapon-updates/weapon_kevlar_and_helmet.mdx";
import WeaponKnife from "@/game-cs2-marketing/weapon-updates/weapon_knife.mdx";
import WeaponM4A1 from "@/game-cs2-marketing/weapon-updates/weapon_m4a1.mdx";
import WeaponM4A1S from "@/game-cs2-marketing/weapon-updates/weapon_m4a1_silencer.mdx";
import WeaponM249 from "@/game-cs2-marketing/weapon-updates/weapon_m249.mdx";
import WeaponMAC10 from "@/game-cs2-marketing/weapon-updates/weapon_mac_10.mdx";
import WeaponMAG7 from "@/game-cs2-marketing/weapon-updates/weapon_mag7.mdx";
import WeaponMolotov from "@/game-cs2-marketing/weapon-updates/weapon_molotov.mdx";
import WeaponMP5SD from "@/game-cs2-marketing/weapon-updates/weapon_mp5sd.mdx";
import WeaponMP7 from "@/game-cs2-marketing/weapon-updates/weapon_mp7.mdx";
import WeaponMP9 from "@/game-cs2-marketing/weapon-updates/weapon_mp9.mdx";
import WeaponNegev from "@/game-cs2-marketing/weapon-updates/weapon_negev.mdx";
import WeaponNova from "@/game-cs2-marketing/weapon-updates/weapon_nova.mdx";
import WeaponP90 from "@/game-cs2-marketing/weapon-updates/weapon_p90.mdx";
import WeaponP250 from "@/game-cs2-marketing/weapon-updates/weapon_p250.mdx";
import WeaponRevolver from "@/game-cs2-marketing/weapon-updates/weapon_revolver.mdx";
import WeaponSawedOff from "@/game-cs2-marketing/weapon-updates/weapon_sawedoff.mdx";
import WeaponScar20 from "@/game-cs2-marketing/weapon-updates/weapon_scar20.mdx";
import WeaponSG556 from "@/game-cs2-marketing/weapon-updates/weapon_sg556.mdx";
import WeaponSmoke from "@/game-cs2-marketing/weapon-updates/weapon_smoke.mdx";
import WeaponSSG08 from "@/game-cs2-marketing/weapon-updates/weapon_ssg08.mdx";
import WeaponTaser from "@/game-cs2-marketing/weapon-updates/weapon_taser.mdx";
import WeaponTec9 from "@/game-cs2-marketing/weapon-updates/weapon_tec9.mdx";
import WeaponUMP45 from "@/game-cs2-marketing/weapon-updates/weapon_ump45.mdx";
import WeaponUSPSilecer from "@/game-cs2-marketing/weapon-updates/weapon_usp_silencer.mdx";
import WeaponXM1014 from "@/game-cs2-marketing/weapon-updates/weapon_xm1014.mdx";

type WeaponUpdates = {
  [key: string]: ComponentType;
};

const WEAPON_UPDATES: WeaponUpdates = {
  weapon_deagle: WeaponDeagle,
  weapon_revolver: WeaponRevolver,
  weapon_elite: WeaponElite,
  weapon_fiveseven: WeaponFiveSeven,
  weapon_glock: WeaponGlock,
  weapon_hkp2000: WeaponHKP2000,
  weapon_usp_silencer: WeaponUSPSilecer,
  weapon_p250: WeaponP250,
  weapon_cz75a: WeaponCZ75A,
  weapon_tec9: WeaponTec9,
  weapon_mag7: WeaponMAG7,
  weapon_nova: WeaponNova,
  weapon_sawedoff: WeaponSawedOff,
  weapon_xm1014: WeaponXM1014,
  weapon_bizon: WeaponBizon,
  weapon_mac_10: WeaponMAC10,
  weapon_mp7: WeaponMP7,
  weapon_mp5sd: WeaponMP5SD,
  weapon_mp9: WeaponMP9,
  weapon_p90: WeaponP90,
  weapon_ump45: WeaponUMP45,
  weapon_ak47: WeaponAK47,
  weapon_aug: WeaponAUG,
  weapon_famas: WeaponFamas,
  weapon_galilar: WeaponGalilar,
  weapon_m4a1: WeaponM4A1,
  weapon_m4a1_silencer: WeaponM4A1S,
  weapon_sg556: WeaponSG556,
  weapon_awp: WeaponAWP,
  weapon_g3sg1: WeaponG3SG1,
  weapon_scar20: WeaponScar20,
  weapon_ssg08: WeaponSSG08,
  weapon_m249: WeaponM249,
  weapon_negev: WeaponNegev,
  weapon_knife: WeaponKnife,
  weapon_taser: WeaponTaser,
  c4: WeaponC4,
  defuseKit: WeaponDefuseKit,
  kevlarAndHelmet: WeaponKevlarAndHelmet,
  decoy: WeaponDecoy,
  weapon_incgrenade: WeaponIncendiary,
  weapon_flashbang: WeaponFlashBang,
  weapon_smoke: WeaponSmoke,
  weapon_molotov: WeaponMolotov,
  weapon_hegrenade: WeaponHEGrenade,
};

export default WEAPON_UPDATES;
