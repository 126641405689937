import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "April 10, 2020"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "AUG – Improved standing accuracy while unscoped. Slightly reduced scoped accuracy."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 18, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Adjustments to the AUG to bring its utility more in line with other rifles."
      }), "\n", _jsx(_components.li, {
        children: "Slightly reduced rate of fire from 666 RPM to 600 RPM."
      }), "\n", _jsx(_components.li, {
        children: "Reduced accuracy while unscoped."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "March 13, 2019"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Now that players have had an opportunity to learn to love the AUG, returning the weapon to its original price of $3300."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "October 9, 2018"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "AUG price reduced to $3150"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "September 28, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased fidelity and reduced distortion in fire sounds for Famas, Galil, Aug, SG553, M4A4, M4A1-S, unsilenced M4A1-S, and AK47."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 27, 2016"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Added tracers to alt fire on Aug, Sg, Glock, and Famas."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 20, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Scope dot no longer fades too quickly during online play."
      }), "\n", _jsx(_components.li, {
        children: "Scope dot is slightly more visible against bright backgrounds."
      }), "\n", _jsx(_components.li, {
        children: "Scope dot is now tinted using the player’s crosshair color settings."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 13, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Aug and SG 553 scope modes now correctly respect players’ zoomed mouse sensitivity settings."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 12, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Weapon adjustments based on data collected:"
      }), "\n", _jsx(_components.li, {
        children: "Increased Aug recoil"
      }), "\n", _jsx(_components.li, {
        children: "Reduced Aug rate of fire"
      }), "\n", _jsx(_components.li, {
        children: "Reduced Aug and Sg553 scoped run speeds"
      }), "\n", _jsx(_components.li, {
        children: "Improved scope visuals for Aug and Sg553."
      }), "\n", _jsx(_components.li, {
        children: "Aug now has a new firing sound."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "February 5, 2014"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Weapon balance has been adjusted:"
      }), "\n", _jsx(_components.li, {
        children: "Improved Sg553 and AUG rates of fire."
      }), "\n", _jsx(_components.li, {
        children: "Improved Sg553 and AUG scoped control ( reduced inaccuracy and recoil )."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "July 3, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Additional minor buffs to the Aug and Sig."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 26, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Further adjustments to the AUG/SG553."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "June 19, 2013"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Tuned the AUG and SG553 to function more effectively as a rifle/sniper hybrid."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
